<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="shiftWarp">
      <v-row class="infoWarp">
        <v-col cols="2">
          <div class="lblText lblTitle">
            <h3>{{ $t('messages.college_name') }}</h3>
          </div>
        </v-col>
        <v-col cols="8">
          <div class="align-self-center mr-9">
            <h3>{{ college.name ? college.name : '' }}</h3>
          </div>
        </v-col>
      </v-row>
      <validation-observer
        ref="obValidate"
        v-slot="{ handleSubmit }"
      >
        <v-form
          ref="formCreate"
          lazy-validation
          @submit.prevent="handleSubmit(signUpSubmit)"
        >
          <v-row>
            <v-col
              cols="2"
              class="titleFlex"
            >
              <div class="lblText lblTitle">
                <h3>{{ $t('messages.vacationRegistration') }}</h3>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="lblText departmentText">
                <h3>{{ $t('messages.vacationName') }}</h3>
              </div>
              <div class="align-self-center mr-9">
                <validation-provider
                  v-slot="{ errors }"
                  name="vacationName"
                  rules="required"
                >
                  <v-text-field
                    v-model="vacationName"
                    :error-messages="errors"
                    outlined
                    placeholder="夏休みなど"
                  />
                </validation-provider>
              </div>
            </v-col>
            <v-col cols="5">
              <div class="lblText dateRadio">
                <h3>{{ $t('messages.holidayPeriod') }}</h3>
              </div>
              <div class="align-self-center mr-9 dateWarp">
                <v-row>
                  <v-col cols="5">
                    <div class="dateStarWrap">
                      <v-row>
                        <!--                          <v-col cols="9">-->
                        <!--                            -->
                        <!--                          </v-col>-->
                        <v-col>
                          <v-menu
                            v-model="showPickerStartAt"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <validation-provider
                                v-slot="{ errors }"
                                name="dateStart"
                                rules="required|date"
                              >
                                <v-text-field
                                  v-model="dateStart"
                                  name="dateStart"
                                  single-line
                                  outlined
                                  clearable
                                  readonly
                                  :error-messages="errors"
                                  hint="YYYY/MM/DD"
                                  append-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </validation-provider>
                            </template>
                            <v-date-picker
                              v-model="dateStart"
                              :first-day-of-week="0"
                              locale="ja-jn"
                              @input="showPickerStartAt = false"
                            />
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <div class="icon-fall">
                      ~
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <div>
                      <v-row>
                        <v-col>
                          <v-menu
                            v-model="showPickerEndAt"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <validation-provider
                                v-slot="{ errors }"
                                name="dateEnd"
                                rules="required|date"
                              >
                                <v-text-field
                                  v-model="dateEnd"
                                  name="dateStart"
                                  single-line
                                  outlined
                                  clearable
                                  readonly
                                  :error-messages="errors"
                                  hint="YYYY/MM/DD"
                                  append-icon="mdi-calendar"
                                  v-bind="attrs"
                                  left
                                  v-on="on"
                                />
                              </validation-provider>
                            </template>
                            <v-date-picker
                              v-model="dateEnd"
                              :min="dateStart"
                              :first-day-of-week="0"
                              locale="ja-jn"
                              @input="showPickerEndAt = false"
                            />
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col
              cols="2"
              class="btnFlex"
            >
              <v-btn
                color="forth"
                class="mb-5 pt-0 white--text"
                large
                block
                type="submit"
              >
                <span class=" font-weight-bold text-h5">
                  {{ $t('messages.signup') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
      <v-row class="d-flex justify-space-between">
        <v-col cols="2">
          <v-select
            v-model="year"
            :items="yearList"
            outlined
            :no-data-text="$t('table.messages.no_data')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="holidayList"
            class="elevation-1 "
            stle
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 100],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
            :no-data-text="$t('table.messages.no_data')"
          >
            <template
              v-slot:footer.page-text
              class="mr-0"
            >
              {{ $t('table.messages.page') }}
              {{ options.page }}
            </template>

            <template v-slot:item.index="props">
              {{ props.index + 1 }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-icon
                color="#5CA6D2"
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil-circle
              </v-icon>
              <v-icon
                dark
                color="#C5DCFA"
                @click="deleteItem(item)"
              >
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-dialog
        v-model="dialog"
        max-width="700px"
      >
        <v-card>
          <v-card-title>
            <!--            <span class="text-h5">{{ formTitle }}</span>-->
          </v-card-title>
          <validation-observer v-slot="{ handleSubmit }">
            <v-form
              ref="form"
              lazy-validation
              @submit.prevent="handleSubmit(updateSubmit)"
            >
              <v-card-text>
                <v-row>
                  <v-col>
                    <div class="lblText departmentText">
                      <h3>{{ $t('messages.vacationName') }}</h3>
                    </div>
                    <div class="align-self-center mr-9">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="vacationName"
                      >
                        <v-text-field
                          v-model="editedItem.name"
                          :error-messages="errors"
                          outlined
                        />
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="lblText dateRadio">
                      <h3>{{ $t('messages.holidayPeriod') }} </h3>
                    </div>
                    <div
                      class="align-self-center mr-9 dateWarp"
                    >
                      <v-row>
                        <v-col cols="5">
                          <validation-provider
                            v-slot="{ errors }"
                            name="dateStart"
                            rules="required|date"
                          >
                            <div class="dateStarWrap">
                              <v-row>
                                <v-col cols="10">
                                  <v-text-field
                                    :value="editedItem.startAt"
                                    name="dateStart"
                                    single-line
                                    outlined
                                    readonly
                                    :error-messages="errors"
                                  />
                                </v-col>
                                <v-col cols="2">
                                  <v-menu
                                    v-model="showPickerStartAtEdit"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        class="mt-4 mr-3"
                                        v-bind="attrs"
                                        left
                                        v-on="on"
                                      >
                                        mdi-calendar
                                      </v-icon>
                                    </template>
                                    <v-date-picker
                                      v-model="editedItem.startAt"
                                      :max="editedItem.endAt"
                                      :first-day-of-week="0"
                                      locale="ja-jn"
                                      @input="showPickerStartAtEdit = false"
                                    />
                                  </v-menu>
                                </v-col>
                              </v-row>
                            </div>
                          </validation-provider>
                        </v-col>
                        <v-col cols="1">
                          <div class="icon-fall">
                            ~
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <validation-provider
                            v-slot="{ errors }"
                            name="dateEnd"
                            rules="required|date"
                          >
                            <div>
                              <v-row>
                                <v-col cols="10">
                                  <v-text-field
                                    :value="editedItem.endAt"
                                    name="dateStart"
                                    single-line
                                    outlined
                                    readonly
                                    :error-messages="errors"
                                  />
                                </v-col>
                                <v-col cols="2">
                                  <v-menu
                                    v-model="showPickerEndAtEdit"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        class="mt-4 mr-3"
                                        v-bind="attrs"
                                        left
                                        v-on="on"
                                      >
                                        mdi-calendar
                                      </v-icon>
                                    </template>
                                    <v-date-picker
                                      v-model="editedItem.endAt"
                                      :min="editedItem.startAt"
                                      :first-day-of-week="0"
                                      locale="ja-jn"
                                      @input="showPickerEndAtEdit = false"
                                    />
                                  </v-menu>
                                </v-col>
                              </v-row>
                            </div>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row class="d-flex justify-end mb-3">
                  <v-col cols="3">
                    <v-btn
                      block
                      color="primary"
                      @click="close"
                    >
                      <span class="text-h5 font-weight-bold"> {{ $t('messages.cancel') }}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      block
                      color="primary"
                      type="submit"
                    >
                      <span class="text-h5 font-weight-bold">{{ $t('messages.save') }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="font-weight-bold text-h4">
            <v-row class="d-flex align-center justify-center">
              <v-col cols="1">
                <v-icon
                  large
                  color="blue darken-2"
                >
                  mdi-delete-outline
                </v-icon>
              </v-col>
              <v-col cols="8">
                この操作は元に戻りません。
                本当に削除してよろしいですか？
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions>
            <v-spacer />

            <v-btn
              class="text-h5"
              dark
              color="#E43E08"
              @click="deleteItemConfirm"
            >
              {{ $t('messages.delete') }}
            </v-btn>
            <v-btn
              class="text-h5"
              @click="close"
            >
              {{ $t('messages.cancel') }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'

  export default {
    name: 'RegisterVacation',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        collegeName: 'ＩＬＰお茶の水医療福祉専門学校',
        dateStart: '',
        dateEnd: '',
        showPickerStartAt: false,
        showPickerEndAt: false,
        showPickerStartAtEdit: false,
        showPickerEndAtEdit: false,
        vacationName: '',
        holidayList: [],
        headers: [],
        mainHeaders: [],
        options: {},
        editMode: false,
        loading: false,
        editedItem: {
          id: '',
          name: '',
          startAt: '',
          endAt: '',
        },
        defaultItem: {
          id: '',
          name: '',
          startAt: '',
          endAt: '',
        },
        editedIndex: '',
        dialog: false,
        dialogDelete: false,
        snackbarMessage: '',
        snackbar: false,
        year: moment(new Date()).format('Y'),
        yearList: [],
      }
    },
    watch: {
      year () {
        this.initialize()
      },

      holiday (value) {
        this.holidayList = _.cloneDeep(value)
      },

      message (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
          this.initialize()
        }
      },
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
    created () {
      this.$store.dispatch('colleague/getCollege', {
        id: this.$route.params.id,
      })

      this.initialize()
    },
    mounted () {
      this.holidayList = _.cloneDeep(this.holiday)
      // Define the rule globally
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      extend('date', {
        validate (value) {
          return moment(value, 'YYYY-mm-DD').format('YYYY-mm-DD') === value
        },
        message: this.$t('validator.invalid_date'),
      })
      this.mainHeaders = [
        {
          text: this.$t('table.headers.index'),
          align: 'start',
          value: 'index',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '4rem',
        },
        {
          text: this.$t('table.headers.holiday'),
          align: 'start',
          value: 'name',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.vacationStartDate'),
          align: 'start',
          value: 'startAt',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.vacationEndDate'),
          align: 'start',
          value: 'endAt',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.action'),
          align: 'start',
          value: 'action',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '4rem',
        },
      ]

      this.headers = _.cloneDeep(this.mainHeaders)
      const max = new Date().getFullYear()
      const min = max - 9

      for (let i = max; i >= min; i--) {
        this.yearList.push(i.toString())
      }
    },

    methods: {
      initialize () {
        const tmpOptions = { ...this.options }
        if (this.year) {
          tmpOptions.year = this.year
        }

        tmpOptions.id = this.$route.params.id

        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('colleague/getCollegeHoliday', tmpOptions)
      },
      async signUpSubmit () {
        const payload = {
          collegeId: _.cloneDeep(this.$route.params.id),
          name: _.cloneDeep(this.vacationName),
          startAt: _.cloneDeep(this.dateStart),
          endAt: _.cloneDeep(this.dateEnd),
        }
        await this.$store.dispatch('colleague/createHoliday', payload)
        this.vacationName = ''
        this.dateStart = ''
        this.dateEnd = ''
        this.$refs.obValidate.reset()
      },
      editItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      updateSubmit () {
        const payload = {
          id: this.editedIndex,
          name: this.editedItem.name,
          startAt: this.editedItem.startAt,
          endAt: this.editedItem.endAt,
        }
        this.$store.dispatch('colleague/updateHoliday', payload)
        this.close()
      },
      close () {
        this.dialog = false
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = ''
        })
      },
      deleteItemConfirm () {
        this.$store.dispatch('colleague/deleteHoliday', {
          id: this.editedIndex,
        })
        this.close()
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
    computed: {
      ...get('colleague', ['message', 'status', 'error', 'holiday', 'college']),
    },
  }
</script>

<style scoped lang="sass">
.border-right
  border-right: thin solid rgba(0, 0, 0, 0.12) !important

.shiftWarp
  margin-top: 15px
  padding: 0 50px

  .infoWarp
    padding-top: 20px

  .lblText
    margin-top: 10px

  .lblTitle
    color: #5CA6D2

.dateWarp
  display: flex
  justify-content: space-between

  .icon-fall
    font-size: 20px
    font-weight: bold
    margin-top: 10px

.titleFlex
  display: flex
  align-items: center

.btnFlex
  display: flex
  align-items: center

  button
    margin: 0px !important

.shiftAt
  margin-left: 0px !important
</style>
